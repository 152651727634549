<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row>
                <b-col md="12">
                    <ValidationProvider
                    name="Uang Harian"
                    rules="required"
                    v-slot="{ errors }"
                    >
                        <b-form-group>
                            <label>Uang Harian <span class="fs-6 text-danger">*</span></label>
                             <vue-numeric v-model="model.daily_trip_money" name="daily_trip_money" @keypress.native="NumbersOnly" class="form-control" placeholder="Masukkan Uang Harian" currency="Rp" separator="."></vue-numeric>
                            <p class="fs-6 text-danger">
                            {{ errors[0] }}
                            </p>
                        </b-form-group>
                    </ValidationProvider>
                    <p class="fs-6 text-danger" v-if="errors.daily_trip_money != ''" style="margin-top: -10px;">
                      {{ errors.daily_trip_money[0] }}
                    </p>
                </b-col>
                <b-col md="12">
                    <ValidationProvider
                    name="Pph21"
                    rules="required"
                    v-slot="{ errors }"
                    >
                        <b-form-group>
                            <label>Pph21 <span class="fs-6 text-danger">*</span></label>
                             <vue-numeric v-model="model.pph21" name="pph21" @keypress.native="NumbersOnly" class="form-control" placeholder="Masukkan PPH21" currency="Rp" separator="."></vue-numeric>
                            <p class="fs-6 text-danger">
                            {{ errors[0] }}
                            </p>
                        </b-form-group>
                    </ValidationProvider>
                    <!-- <p class="fs-6 text-danger" v-if="errors.pph21 != ''" style="margin-top: -10px;">
                      {{ errors.pph21 }}
                    </p> -->
                </b-col>
            </b-row>
            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Simpan
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'pph21' }"
              >
                Batal
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BImg,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      name: "UsersForm",
      errors: {
        daily_trip_money: '',
        pph21: ''
      },
      showDismissibleAlert: false,
      config: {
        api: "/pph21",
        redirect: "pph21",
      },
      model: {
        daily_trip_money: '',
        pph21: ''
      },
      logos:null,
      logo_prev:null,
      confirmPass: null,
      error_link:true,
      messages: "",
    };
  },
  mounted() {
    let _ = this;
    if (this.$route.meta.action != "store") {
      _.getData();
    }
  },
  methods: {
    NumbersOnly(evt) {
      evt = (evt) ? evt : window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        axios
          .get(_.config.api + "/" + this.$route.params.id)
          .then((response) => {
            let _ = this;
            _.model = response.data.data;
          });
      }
    },
    save() {
      let _ = this;
      if (this.$route.meta.action == "store") {
        axios
          .post(_.config.api, _.model)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Tambah Data Berhasil",
                text: "Data baru berhasil ditambahkan",
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.errors = e.response.data.data;
            } else {
              vm.messages = e.response.data.message;
              vm.errors = e.response.data.data;
            }
          });
      } else {
        _.model.id = _.$route.params.id;
        axios
          .put(_.config.api + "/" + _.$route.params.id, _.model)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Ubah Data Berhasil",
                text: "Data berhasil diubah",
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.errors = e.response.data.data;
            } else {
              vm.messages = e.response.data.message;
              vm.errors = e.response.data.data;
            }
          });
      }
    },
  },
};
</script>
